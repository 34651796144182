<template>
  <div class="container">
    <header class="jumbotron">
      <h3>
        <strong>Alterar senha</strong> 
      </h3>
    </header>
    <div class="form-group col-12">
      <label for="name">Senha atual</label>
      <input type="password" class="form-control" id="name" v-model="passwords.current" autofocus="autofocus" required="required" maxlength="10">
    </div>
    <div class="form-group col-12">
      <label for="name">Nova senha</label>
      <input type="password" class="form-control" id="name" v-model="passwords.new" autofocus="autofocus" required="required" maxlength="10">
    </div>
    <div class="form-group col-12">
      <label for="name">Confirma nova senha</label>
      <input type="password" class="form-control" id="name" v-model="passwords.repeat" autofocus="autofocus" required="required" maxlength="10">
    </div>
    <button type="button" class="btn btn-primary btn-lg w-100 p-3" v-on:click="postChangePassword">
      Confirmar
    </button>
    <router-link class="btn btn-default btn-lg w-100 p-3" to="/profile">Cancelar</router-link>
  </div>
</template>

<script>
import ApiAxios from '../services/api-axios';
import { sAlert } from './utils/jalerts';

export default {
  name: 'Profile',
  data() {
    return {
      passwords: {}
    } 
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) return this.$router.push('/login');
  },
  methods: {
    async postChangePassword() {
      if (!this.passwords.current) return sAlert({ type: 'warning', text: 'Ops, informe a senha atual' });
      if (!this.passwords.new) return sAlert({ type: 'warning', text: 'Ops, informe a nova senha' });
      if (!this.passwords.repeat) return sAlert({ type: 'warning', text: 'Ops, repita a nova senha' });
      if (this.passwords.new !== this.passwords.repeat) return sAlert({ type: 'warning', text: 'Ops, a nova senha e a repetição não são iguais' });

			this.$root.loadingVisible = true;
			let that = this;
      ApiAxios.post(`associate/me/changePassword`, { ...this.passwords })
        .then((res) => {
          this.profile = res.data.associate;
          this.$root.loadingVisible = false;
          sAlert({ type: 'success', text: 'Senha alterada com sucesso' });
          that.$router.push({ name: 'profile' });  
        })
        .catch((e) => {
          this.$root.loadingVisible = false;
          if (e.response.status == 403) {
            this.$router.back();
            return sAlert({ type: 'warning', text: 'Ops, ' + e.response.data });  
          }
          sAlert({ type: 'danger', text: 'Ops, houve um erro: ' + e.response.data });
        });
		},
  }
};
</script>